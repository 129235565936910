import React from "react";
import { Link } from 'react-router-dom'

export default function BlogNews() {
    return (
        <section className="blognews mt-10">
            <div className="py-10 lg:py-14">
                <h1 className="lg-max-w-4xl md-max-w-2xl text-3xl lg:text-5xl text-center text-text-color tracking-normal font-medium">Read about some of our
                    <br></br> <span className="font-serif italic text-primary font-bold">work and stories</span>
                </h1>

                <div className="news flex pt-8 px-6 lg:px-0 lg:gap-x-4 flex-col lg:flex-row align-center justify-center">
                    
                    <div className="blog-lg w-full h-auto lg:w-[700px] lg:h-[455px] bg-cover bg-no-repeat"
                        style={{
                            backgroundImage: `linear-gradient(to bottom, rgba(97, 97, 97, 0.3) 30%, rgba(0, 0, 0, 0.9) 90%), url('https://shecluded-medias.s3.us-east-2.amazonaws.com/blog-1.png')`
                        }}> 

                        <div className="blog-lg-content pb-6 mt-48 lg:mt-72 px-6">
                            <h1 className="text-white font-medium text-3xl">Game Changing Pitch Competition</h1>
                            <div className="blog-date flex flex-row text-light-grey-2 text-base pt-4  pb-4">
                                <p>Read</p>
                                <p className="px-2">|</p>
                                <p className="">October 17, 2024</p>
                            </div>
                            <Link to="https://blog.shecluded.com/how-shecluded-implemented-a-game-changing-pitch-competition-at-beauty-in-the-motherland/" className="text-lg font-semibold leading-10 underline-offset-4 tracking-normal text-white underline">
                                Learn more
                            </Link>
                        </div>
                    </div>
                    <div className="blog-sm flex flex-col mt-4 lg:mt-0">
                        <Link to='https://blog.shecluded.com/shecluded-and-fcmb-sheventures-drive-change-with-fempreneur-accelerator/' className="">
                            <div className="blog-sm-content flex flex-col lg:flex-row gap-y-4 lg:gap-y-0">
                                <img src="https://shecluded-medias.s3.us-east-2.amazonaws.com/blog-news-fcmb.jpg" className="w-full h-auto lg:w-[200px] lg:h-[130px] object-cover" alt="women at a shecluded event"></img>
                                <div className="blog-details text-dark-grey-2 pt-0 lg:pl-2">
                                    <p className="text-sm lg:text-base font-medium">SHECLUDEDNEWS</p>
                                    <h1 className="text-text-color font-semibold text-base lg:text-base uppercase pt-2">Shecluded and FCMB SheVentures <br></br>Drive Change with Fempreneur<br></br> Accelerator
                                   </h1>
                                    <div className="blog-date flex flex-row text-dark-grey-2 text-sm lg:text-base pt-2">
                                        <p>Read</p>
                                        <p className="px-2">|</p>
                                        <p className="">January 6, 2025</p>
                                    </div>
                                </div>

                            </div>
                        </Link>

                        <Link to='https://blog.shecluded.com/the-she-tank-and-bellanaija-launch-groundbreaking-film-for-2024-womens-economic-power-campaign/' className="py-6">
                            <div className="blog-sm-content flex flex-col lg:flex-row gap-y-4  lg:gap-y-0">
                                <img src="https://shecluded-medias.s3.us-east-2.amazonaws.com/blog+image+3.png" className="w-full h-auto lg:w-[200px] lg:h-[130px] object-cover" alt="women at a shecluded event"></img>
                                <div className="blog-details text-dark-grey-2 pt-0 lg:pl-2">
                                    <p className="text-sm lg:text-base font-medium">SHECLUDEDNEWS</p>
                                    <h1 className="text-text-color font-semibold text-base lg:text-base uppercase pt-2">The She Tank and BellaNaija Launch<br></br> Film for 2024 Women’s Economic<br></br> Empowerment Campaign</h1>
                                    <div className="blog-date flex flex-row text-dark-grey-2 text-sm lg:text-base pt-2">
                                        <p>Read</p>
                                        <p className="px-2">|</p>
                                        <p className="">October 9, 2024</p>
                                    </div>
                                </div>

                            </div>
                        </Link>

                        <Link to='https://blog.shecluded.com/the-impact-of-shecludeds-dream-to-business-program/'>
                            <div className="blog-sm-content flex flex-col lg:flex-row gap-y-4  lg:gap-y-0">
                                <img src="https://shecluded-medias.s3.us-east-2.amazonaws.com/Blog+image+4.png" className="w-full h-auto lg:w-[200px]  lg:h-[130px]  object-cover" alt="women at a shecluded event"></img>
                                <div className="blog-details text-dark-grey-2 lg:pt-4 lg:pl-2">
                                    <p className="text-sm lg:text-basefont-medium">SHECLUDEDNEWS</p>
                                    <h1 className="text-text-color font-semibold text-md lg:text-base uppercase pt-1">The Impact of Shecluded’s Dream to<br></br> Business Program</h1>
                                    <div className="blog-date flex flex-row text-dark-grey-2 text-sm lg:text-base pt-1">
                                        <p>Read</p>
                                        <p className="px-2">|</p>
                                        <p className=""> Apr 25, 2024</p>
                                    </div>
                                </div>

                            </div>
                        </Link>

                    </div>
                </div>
            </div>
        </ section>
    )
}

