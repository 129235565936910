import NavBar from "./navbar_gold";
import { ArrowRightIcon } from "@heroicons/react/20/solid";

export default function Header() {
  return (
    <header className="gold-header bg-background-grey bg-hero-pattern">
      <NavBar />
      <div className="flex flex-col lg:flex-row lg:justify-between">
        <div className="title pb-10 lg:pb-0 lg:pt-10 pl-6 lg:pl-20">
          <h1 className="max-w-sm lg:max-w-2xl text-4xl lg:text-6xl text-text-color tracking-normal font-semibold lg:pt-4 text-center lg:text-left leading-[3rem] lg:leading-[4rem]"><span className="font-serif italic text-primary font-semibold ">
            <br></br>Shecluded Gold</span> - <br></br>Build, Scale, and Thrive in Business
          </h1>

          <p className="max-w-sm md:max-w-xl py-4 lg:py-6 text-dark-grey-1 text-base lg:text-lg text-center lg:text-justify  font-normal">Success in business isn’t just about working hard, it’s about having access. <span className="font-semibold">Access to the right people,  Access to the right resources,</span> and <span className="font-semibold">Access to the right support system.</span> Shecluded Gold is your gateway to that access.</p>
          <div className="flex items-center justify-center lg:items-start lg:justify-start">
            <a href="/gold/register" className="inline-flex rounded-full bg-black-1 py-4 px-16 text-base font-medium text-light-grey-4 drop-shadow-lg  transition duration-0 ease-in hover:drop-shadow-xl hover:duration-150">
              Register Now
              <ArrowRightIcon className="ml-1 h-6 w-6 text-gray-500" aria-hidden="true" />
            </a>
          </div>
        </div>
        <div className="relative hero-img">
          <img src="https://shecluded-medias.s3.us-east-2.amazonaws.com/gold-header.png" className="w-[450px] lg:w-[550px] h-auto" alt="woman in pink blouse with pink scarf under gold background"></img>
        </div>
      </div>
    </header>
  )

}

