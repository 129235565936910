import React from "react";
import { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './styles/style.css';

//Routes
import HomePage from 'pages/homepage/homePage';
import Emptypage from 'pages/404page/404.js';
import GoldPage from "pages/goldpage/gold";

const AboutPage = React.lazy(() => import("pages/aboutpage/about"));
const LoanPage = React.lazy(() => import("pages/loanpage/loans"));
const HubPage = React.lazy(() => import("pages/hubpage/hub"));
const SupportPage = React.lazy(() => import("pages/bussupportpage/support"));
const ContactPage = React.lazy(() => import("pages/contactpage/contact"));
const CareersPage = React.lazy(() => import("pages/careerspage/career"));
const CommunityPage = React.lazy(() => import("pages/community/community"));
const PressPage = React.lazy(() => import("pages/presspage/press"));
const StoriesPage = React.lazy(() => import("pages/impactstories/stories"));
const MentorsPage = React.lazy(() => import("pages/mentorspage/mentor"));
const TermsPage = React.lazy(() => import("pages/termspage/terms"));
const LearnPage = React.lazy(() => import("pages/learnpage/learn"));
const GoldRegisterPage = React.lazy(() => import("pages/goldpage/register/register"));
const GoldRegisterSucessPage = React.lazy(() => import("pages/goldpage/register/register_success"));
const GoldFaqPage = React.lazy(() => import("pages/goldpage/faqs/faq_main"));
const GoldIwdPage = React.lazy(() => import("pages/goldpage/promotion/iwdpage/iwd"));
const BestiePage = React.lazy(() => import("pages/businessbestiepage/bestie"));
const GoldPricingPage = React.lazy(() => import("pages/goldpage/register/pricing"));




function App() {
  return (
    <Router>
      <Suspense fallback={<div>Impacting women financially....</div>} >
        <Routes>
          <Route
            path="/about"
            element={
              <Suspense >
                <AboutPage />
              </Suspense>
            }
          />
          <Route
            path="/loans"
            element={
              <Suspense >
                <LoanPage />
              </Suspense>
            }
          />
          <Route
            path="/hub"
            element={
              <Suspense >
                <HubPage />
              </Suspense>
            }
          />
          <Route
            path="/business-support"
            element={
              <Suspense >
                <SupportPage />
              </Suspense>
            }
          />
          <Route
            path="/contact-us"
            element={
              <Suspense >
                <ContactPage />
              </Suspense>
            }
          />
          <Route
            path="/careers"
            element={
              <Suspense>
                <CareersPage />
              </Suspense>
            }
          />
          <Route
            path="/community"
            element={
              <Suspense>
                <CommunityPage />
              </Suspense>
            }
          />
          <Route
            path="/press"
            element={
              <Suspense >
                <PressPage />
              </Suspense>
            }
          />
          <Route
            path="/stories"
            element={
              <Suspense >
                <StoriesPage />
              </Suspense>
            }
          />
          <Route
            path="/mentors"
            element={
              <Suspense >
                <MentorsPage />
              </Suspense>
            }
          />
          <Route
            path="/terms"
            element={
              <Suspense >
                <TermsPage />
              </Suspense>
            }
          />
          <Route
            path="/learn"
            element={
              <Suspense >
                <LearnPage />
              </Suspense>
            }
          />
          <Route
            path="/loan"
            element={
              <Suspense >
                <LoanPage />
              </Suspense>
            }
          />

          <Route
            path="/gold/register"
            element={
              <Suspense >
                <GoldRegisterPage />
              </Suspense>
            }
          />

          <Route
            path="/gold/pricing"
            element={
              <Suspense >
                <GoldPricingPage />
              </Suspense>
            }
          />

          <Route
            path="/gold/register/success"
            element={
              <Suspense >
                <GoldRegisterSucessPage />
              </Suspense>
            }
          />

          <Route
            path="/gold/faq"
            element={
              <Suspense >
                <GoldFaqPage />
              </Suspense>
            }
          />

          <Route
            path="/gold/promotion/iwd"
            element={
              <Suspense >
                <GoldIwdPage />
              </Suspense>
            }
          />


          <Route
            path="/business-bestie"
            element={
              <Suspense >
                <BestiePage />
              </Suspense>
            }
          />

          <Route path="/" element={<HomePage />} />
          <Route path="*" element={<Emptypage />} />
          <Route path="/gold" element={<GoldPage />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
