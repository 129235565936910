
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import ScrollAnimation from "react-animate-on-scroll";


export default function Remark() {
    return (
        <section className="bg-background-grey  gold-support px-6 lg:px-20 py-10">
            <div className="content flex flex-col lg:flex-row items-center lg:justify-between">
                <div className="col flex flex-col">
                    <div className="col-content">
                        <div className="title pt-4 lg:pt-10">
                            <h1 className=" text-4xl lg:text-6xl text-text-color tracking-normal font-semibold pt-4 lg:leading-[3.5rem]">For the Woman <span className="font-serif italic text-primary font-semibold ">
                                <br></br>Who Wants More</span>
                            </h1>

                            <p className="max-w-sm lg:max-w-lg py-4 lg:py-6 text-dark-grey-1 text-base text-justify  font-normal">Shecluded Gold is more than just a membership, it’s a commitment to your growth and success. We created this to give ambitious women like you the resources, support, and network needed to thrive in business. Join us, and let’s build something extraordinary together.</p>
                            <a href="/gold/register" className="inline-flex items-center rounded-full bg-black-1 py-4 px-16 text-base font-medium text-light-grey-4 drop-shadow-lg  transition duration-0 ease-in hover:drop-shadow-xl hover:duration-150">
                                Let’s do This
                                <ArrowRightIcon className="ml-1 h-6 w-6 text-gray-500" aria-hidden="true" />
                            </a>
                        </div>
                    </div>
      
                    <div className="hidden lg:flex col-content mt-10">
                        <ScrollAnimation animateIn='fadeIn' >
                            <img src="https://shecluded-medias.s3.us-east-2.amazonaws.com/ceo-gold-2.png" className="w-full lg:w-[500px] h-auto" alt="shecluded ceo"></img>
                            </ScrollAnimation>
                    </div>

                </div>

                <div className="col flex flex-col">
                    <div className="col-content mt-10 lg:mt-0">  
                        <img src="https://shecluded-medias.s3.us-east-2.amazonaws.com/ceo-gold-1.png" className="w-full lg:w-[550px] h-auto" alt="shecluded ceo"></img>
                    </div>
                    <div className="col-content flex flex-col lg:flex-row mt-8 lg:mt-4">
                        <img src="https://shecluded-medias.s3.us-east-2.amazonaws.com/84e5ad3654ce24b07830cea90f4214fd.png" alt="quote" className="rotate-180 w-[70px] lg:w-[80px] h-[70px] lg:h-[80px]"></img>
                        <div className="relative lg:w-[450px] mt-6 lg:mt-20">
                            <label className="absolute -top-5 left-6 text-2xl lg:text-3xl font-bold text-text-color bg-background-grey px-1">Message from the CEO</label>
                            <div className="border border-black rounded-3xl px-4 py-6 w-full">
                                <p className="text-base lg:text-lg text-dark-grey-1">My mum was a widow at 27, and being the first of four kids, growing up was tough.</p>
                                <p className="text-base lg:text-lg text-dark-grey-1 mt-4">I changed my story—worked at a Big 4 consulting firm, raised millions of dollars to do my work, grew Shecluded, became a Halcyon and Echoing Green Fellow, and now, with Shecluded Gold, I’m looking to raise a community of normal women who want to rewrite their own stories. </p>
                            </div>
                        </div>

                        <div className="lg:hidden col-content mt-10">
                                <img src="https://shecluded-medias.s3.us-east-2.amazonaws.com/ceo-gold-2.png" className="w-full lg:w-[500px] h-auto" alt="woman in white top and jean holding hands on her head"></img>
                        </div>
                    </div>



                </div>
            </div>
        </section>
    )
}
