import React, { useRef } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { ChevronLeftIcon, ChevronRightIcon, XMarkIcon } from "@heroicons/react/24/solid";

function PurposeSlide() {
    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };

    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 2.5,
        slidesToScroll: 2,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 1024, // Adjust for tablets
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768, // Mobile view
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <section className="bg-white lg:pl-20 py-10 lg:py-20">
            <div className="slider-container">
                <div className="lg:pr-20 flex flex-col lg:flex-row lg:items-center lg:justify-between mb-8 mx-4 lg:mx-2">
                    <h1 className="text-3xl lg:text-6xl lg:max-w-2xl text-center lg:text-start">Why This Membership Matters for <span className="font-serif italic text-primary font-semibold ">Business Growth</span>  </h1>
                    <div className="arrows mt-6 flex items-center justify-center">
                        <button className="button mx-2" onClick={previous} >
                            <ChevronLeftIcon className=" w-10 lg:w-14 h-10 lg:h-12 p-2 text-black bg-background-grey rounded-lg stroke-2"></ChevronLeftIcon>
                        </button>
                        <button className="button" onClick={next}>
                            <ChevronRightIcon className="w-10 lg:w-14 h-10 lg:h-12  p-2 text-black bg-light-pink-5 rounded-lg stroke-2"></ChevronRightIcon>
                        </button>
                    </div>
                </div>

                <Slider
                    ref={slider => {
                        sliderRef = slider;
                    }}
                    {...settings}
                >
                    <div className="px-4">
                        <div className="lg:w-[530px] lg:h-[320px] relative transition-all duration-300 cursor-pointer bg-primary-light-5 rounded-xl  pl-6 pr-6 lg:pr-24 py-4 lg:py-10">
                            <div className="mt-4 flex flex-col lg:flex-row ">
                                <XMarkIcon className="text-primary size-8 lg:size-14 mr-4 mt-3 mb-3 lg:mb-0"></XMarkIcon>
                                <div classname="content">
                                    <p className="text-2xl lg:text-3xl text-black-2 font-medium">Increased Credibility
                                    </p>
                                    <p className="lg:max-w-2xl text-base text-black-2 pt-3">A professional business address enhances trust with clients and investors.
                                    </p>
                                </div>
                            </div>
                            <div className="pt-6 lg:pt-20 flex items-center justify-center">
                                <h1 className="text-text-color-1/70 text-3xl font-semibold">01</h1>
                                <div className="w-full h-px bg-text-color-1/70 ml-6"></div>
                            </div>
                        </div>
                    </div>

                    <div className="px-4">
                        <img src="https://shecluded-medias.s3.us-east-2.amazonaws.com/gold-purpose-1.png" alt="woman in shecluded hub" className="w-auto lg:w-[530px] lg:h-[318px]"></img>
                    </div>


                    <div className="px-4">
                        <div className="lg:w-[530px] lg:h-[320px] relative transition-all duration-300 cursor-pointer bg-light-yellow-1 rounded-xl  pl-6 pr-6 lg:pr-24 py-4 lg:py-10">
                            <div className="mt-4 flex flex-col lg:flex-row ">
                                <XMarkIcon className="text-yellow-2 size-8 lg:size-12 mr-4 mt-3  mb-3 lg:mb-0"></XMarkIcon>
                                <div classname="content">
                                    <p className="text-2xl lg:text-3xl text-black-2 font-medium">Networking Opportunities
                                    </p>
                                    <p className="lg:max-w-2xl text-base text-black-2 pt-3">Access to a community of successful women entrepreneurs.
                                    </p>
                                </div>
                            </div>
                            <div className="pt-6 lg:pt-20 flex items-center justify-center">
                                <h1 className="text-text-color-1/70 text-3xl font-semibold">02</h1>
                                <div className="w-full h-px bg-text-color-1/70 ml-6"></div>
                            </div>
                        </div>
                    </div>

                    <div className="px-4">
                        <img src="https://shecluded-medias.s3.us-east-2.amazonaws.com/gold-purpose-2.png" alt="women in shecluded hub" className="w-auto lg:w-[530px] lg:h-[318px]"></img>
                    </div>


                    <div className="px-4">
                        <div className="lg:w-[530px] lg:h-[320px] relative transition-all duration-300 cursor-pointer bg-light-pink-1 rounded-xl  pl-6 pr-6 lg:pr-24  py-4 lg:py-10">
                            <div className="mt-4 flex flex-col lg:flex-row ">
                                <XMarkIcon className="text-secondary size-8 lg:size-10 mr-4 mt-3  mb-3 lg:mb-0"></XMarkIcon>
                                <div classname="content">
                                    <p className="text-2xl lg:text-3xl text-black-2 font-medium">Business Financing
                                    </p>
                                    <p className="lg:max-w-2xl text-base text-black-2 pt-3">Affordable loans and grant support to help you grow.
                                    </p>
                                </div>
                            </div>
                            <div className="pt-6 lg:pt-20 flex items-center justify-center">
                                <h1 className="text-text-color-1/70 text-3xl font-semibold">03</h1>
                                <div className="w-full h-px bg-text-color-1/70 ml-6"></div>
                            </div>
                        </div>
                    </div>

                    <div className="px-4">
                        <img src="https://shecluded-medias.s3.us-east-2.amazonaws.com/gold-purpose-5.png" alt="women in shecluded hub" className="w-auto lg:w-[530px] lg:h-[318px]"></img>
                    </div>

                    <div className="px-4">
                        <div className="lg:w-[530px] lg:h-[320px] relative transition-all duration-300 cursor-pointer bg-light-yellow-2 rounded-xl  pl-6 pr-6 lg:pr-24  py-4 lg:py-10 ">
                            <div className="mt-4 flex flex-col lg:flex-row ">
                                <XMarkIcon className="text-pale-yellow size-8 lg:size-14 mr-4 mt-3 mb-3 lg:mb-0"></XMarkIcon>
                                <div classname="content">
                                    <p className="text-2xl lg:text-3xl text-black-2 font-medium">Convenient Work & Meeting Spaces
                                    </p>
                                    <p className="lg:max-w-2xl text-base text-black-2 pt-3">Reduce operational costs with access to premium coworking and meeting spaces.
                                    </p>
                                </div>
                            </div>
                            <div className="pt-6 lg:pt-16 flex items-center justify-center">
                                <h1 className="text-text-color-1/70 text-3xl font-semibold">04</h1>
                                <div className="w-full h-px bg-text-color-1/70 ml-6"></div>
                            </div>
                        </div>
                    </div>

                    <div className="px-4">
                        <img src="https://shecluded-medias.s3.us-east-2.amazonaws.com/gold-purpose-3.png" alt="women in shecluded hub" className="w-auto lg:w-[530px] lg:h-[318px]"></img>
                    </div>

                    <div className="px-4">
                        <div className="lg:w-[530px] lg:h-[320px] relative transition-all duration-300 cursor-pointer bg-light-green-6 rounded-xl  pl-6 pr-6 lg:pr-24 py-4 lg:py-10">
                            <div className="mt-4 flex flex-col lg:flex-row ">
                                <XMarkIcon className="text-green-4 size-8 lg:size-14 mr-4 mt-3 mb-3 lg:mb-0"></XMarkIcon>
                                <div classname="content">
                                    <p className="text-2xl lg:text-3xl text-black-2 font-medium">Business Growth Support
                                    </p>
                                    <p className="lg:max-w-2xl text-base text-black-2 pt-3">Learn strategies and gain insights that position your business for long-term success.
                                    </p>
                                </div>
                            </div>
                            <div className="pt-6 lg:pt-20 flex items-center justify-center">
                                <h1 className="text-text-color-1/70 text-3xl font-semibold">05</h1>
                                <div className="w-full h-px bg-text-color-1/70 ml-6"></div>
                            </div>
                        </div>
                    </div>

                    <div className="px-4">
                        <img src="https://shecluded-medias.s3.us-east-2.amazonaws.com/gold-purpose-4.png" alt="women in shecluded hub" className="w-auto lg:w-[530px] lg:h-[318px]"></img>
                    </div>

                </Slider>
            </div>
        </section>
    );
}

export default PurposeSlide;
