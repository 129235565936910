
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { ArrowRightIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'


const Faqs = [
   { question: "What is Shecluded Gold & Elite Membership?", answer: "Shecluded Gold & Elite is an exclusive membership designed for women entrepreneurs looking to scale their businesses. It provides access to essential business services, funding opportunities, networking, and workspace solutions to support business growth."},
   { question: "Who is Shecluded Gold & Elite for?", answer: "Shecluded Gold & Elite is designed for women entrepreneurs, business owners, and professionals who want to position their businesses for growth with a credible business address, workspace access, funding opportunities, and strategic support."},
   { question: "Can I use the business address even if I don’t live in Lagos?", answer: "Yes! You can use our verified Lagos business address to boost credibility and receive business mail, no matter where you are based."},
   { question: "What type of businesses can benefit from Shecluded Gold & Elite?", answer: "Any business! Whether you’re in fashion, beauty, consulting, tech, food, health, retail, or services, Shecluded Gold & Elite helps you build a structured and scalable business"},
   { question: "How long does my membership last?", answer: "Your membership runs for one year (April 28, 2025 – April 28, 2026). Applications are open from February 18 – April 20, 2025."},
]


export default function GoldFaq() {
    return (
        <section className="flex flex-col items-center justify-center px-6 lg:px-20 py-10 lg:py-20 -mb-20">
            <h1 className="lg-max-w-4xl md-max-w-xl text-3xl lg:text-5xl  text-center text-text-color  tracking-normal font-semibold">FAQ </h1>
                        <div className="w-full pt-6 lg:pt-10 px-6 lg:px-14">
                            <div className="w-full">
                                {Faqs.map((faq, index) => (
                                <Disclosure as="div" className="rounded-2xl lg:rounded-full bg-light-grey-5 py-6 px-6 lg:px-14 mb-6">
                                    <DisclosureButton className="group flex w-full items-center justify-between">
                                        <span className="text-base lg:text-lg font-semibold text-text-color text-left group-data-[hover]:text-primary">
                                               {faq.question}
                                        </span>
                                        <ChevronRightIcon className="size-5 lg:size-7 fill-black group-data-[hover]:fill-primary group-data-[open]:rotate-180" />
                                    </DisclosureButton>
                                    <DisclosurePanel
                                        transition
                                        className="mt-3 text-sm lg:text-base text-dark-grey-1 lg:pr-20 transition duration-200 ease-out">
                                       {faq.answer}
                                    </DisclosurePanel>
                                </Disclosure>
                               ))}
  
                            </div>
                        </div>
                        <Link to={"/gold/faq"}  className="flex  items-center lg-max-w-4xl md-max-w-xl text-xl lg:text-2xl  text-center text-primary  tracking-normal font-semibold mb-10">See More Questions   <ArrowRightIcon className="ml-1 h-6 w-6 mt-1 text-primary" aria-hidden="true" />                        </Link>

        </section>

    )

}







