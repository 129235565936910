
export default function Review() {
    return (
        <section className="gold-review bg-background-grey py-10">

            <div className="test-container bg-yellow-gold rounded-3xl lg:rounded-large p-10 lg:p-24 pb-20 lg:pb-24">
                <div className="test-row flex flex-col lg:flex-row lg:gap-x-20">
                   
                    <div className="test-col lg:w-1/2">
                        <div className="test-title">
                        <h1 className="text-4xl lg:text-6xl lg:max-w-xl text-text-color text-center lg:text-left tracking-normal font-semibold pt-4 lg:leading-[4rem]" ><span className="font-serif italic text-gold-text font-semibold ">Testimonials
                        </span> from People in our
                            <span className="font-serif italic text-gold-text font-semibold"> Community
                            </span>
                        </h1>
                        </div>

                        <div className="test-content">
                        <div className="relative mt-10 lg:mt-12">
                            <label className="absolute -top-4 lg:-top-5 left-5 lg:left-9 text-2xl lg:text-4xl font-semibold text-text-color bg-none px-1">Nelly Agbogu</label>
                            <div className="bg-light-orange-8 rounded-xl px-4 lg:px-10 py-8 lg:py-20 w-full flex flex-col text-justify  items-center justify-center">
                                <p className="text-base lg:text-lg lg:text-xl text-dark-grey-1">I know firsthand the challenges of scaling a business, and Shecluded has been a vital part of my journey. Over the years, they have supported the NBC Fairs with funding, helping us create bigger and better opportunities for vendors. Now, with Shecluded Gold, our women can now access even more business structure, funding, a professional workspace, and the right network to grow.</p>
                            </div>
                            <div className="absolute -bottom-16 lg:-bottom-20 lg:-left-6">
                            <img src="https://shecluded-medias.s3.us-east-2.amazonaws.com/gold-review-3.png" alt="review from shecluded customers" className="rounded-full  object-cover h-20 w-20 lg:h-36 lg:w-36"></img>
                            </div>
                        </div>
                        </div>

                        <div className="test-content">
                        <div className="relative mt-28 lg:mt-32">
                        <label className="absolute -top-4 lg:-top-5 left-5 lg:left-9 text-2xl lg:text-3xl font-semibold text-text-color bg-none px-1">Umoh Elizabeth Friday</label>
                            <div className="bg-light-orange-8 rounded-xl px-4 lg:px-10  py-8 lg:py-20 w-full flex flex-col text-justify  items-center justify-center">
                                <p className="text-base lg:text-xl text-dark-grey-1">Mentorship and training from Shecluded taught me how to market my business, attract customers and  build a business page, and now selling online and  my business is doing better than ever. </p>
                            </div>
                            <div className="absolute -bottom-16 lg:-bottom-20 lg:-left-6">
                            <img src="https://shecluded-medias.s3.us-east-2.amazonaws.com/gold-review-4.png" alt="review from shecluded customers" className="rounded-full  object-cover h-20 w-20 lg:h-36 lg:w-36"></img>
                            </div>
                        </div>
                        </div>

                    </div>

                    <div className="test-col lg:w-1/2">
                    <div className="test-content">
                        <div className="test-content">
                        <div className="relative mt-28 lg:mt-20 ">
                        <label className="absolute -top-4 lg:-top-5 left-5 lg:left-9 text-2xl lg:text-4xl font-bold text-text-color bg-none px-1">Jennifer Awirigwe</label>
                            <div className="bg-light-orange-8 rounded-xl px-4 lg:px-10  py-8 lg:py-20 w-full flex flex-col text-justify  items-center justify-center">
                                <p className="text-base lg:text-xl text-dark-grey-1">Joining Shecluded gold means that Fintribe will now be at the Shecluded Hub. I am excited about this. The Business consulting with the Shecluded CEO has been very useful to unlock our next level growth</p>
                            </div>
                            <div className="absolute -bottom-14 lg:-right-6">
                           <img src="https://shecluded-medias.s3.us-east-2.amazonaws.com/gold-review-2.png" alt="review from shecluded customers" className="rounded-full  object-cover h-20 w-20 lg:h-36 lg:w-36"></img>
                            </div>
                        </div>
                        </div>
                        </div>

                        <div className="test-content">
                        <div className="test-content">
                        <div className="relative mt-28 lg:mt-36 ">
                        <label className="absolute -top-4 lg:-top-5 left-5 lg:left-9 text-2xl lg:text-4xl font-bold text-text-color bg-none px-1">Elizabeth Akinyemi</label>
                            <div className="bg-light-orange-8 rounded-xl px-4 lg:px-10  py-8 lg:py-20 w-full flex flex-col text-justify  items-center justify-center">
                                <p className="text-base lg:text-xl text-dark-grey-1">Being part of the Shecluded community has truly transformed my business. The mentorship has offered invaluable guidance on structuring my business, developing strategies, and ensuring sustainability.</p>
                            </div>
                            <div className="absolute -bottom-14 lg:-right-6">
                           <img src="https://shecluded-medias.s3.us-east-2.amazonaws.com/gold-review-1.png" alt="review from shecluded customers" className="rounded-full  object-cover h-20 w-20 lg:h-36 lg:w-36"></img>
                            </div>
                        </div>
                        </div>
                        </div>
                    </div>

                </div>
            </div>

        </section>

    )
}



