
import { CheckBadgeIcon } from "@heroicons/react/20/solid"


export default function Benefit() {

    return (

        <section className="bg-background-grey py-10 lg:py-20 px-6 lg:px-24 border-b border-light-grey-1">
            <div className="benefit-row flex flex-col lg:flex-row lg:gap-x-10">
                <div className="benefit flex flex-col gap-y-4 lg:gap-y-10 ">
                    <h1 className="max-w-sm lg:max-w-2xl text-3xl lg:text-6xl text-text-color  text-center lg:text-left tracking-normal font-semibold pt-4 lg:leading-[4rem]">What does your<span className="font-serif italic text-primary font-semibold ">
                        <br></br>membership cover?</span>
                    </h1>

                    <div className="benefit-col">
                        <CheckBadgeIcon className="size-8 lg:size-14 text-secondary -mb-6 lg:-mb-14" />
                        <div className="benefit-box bg-light-orange-9 ml-2 lg:ml-4 px-6 lg:px-12 py-6 rounded-lg">
                            <h2 className="text-lg lg:text-2xl font-medium text-black-1">A Shecluded gold membership card</h2>
                            <p className="text-sm lg:text-base lg:max-w-lg text-dark-grey-1 pt-3">This card gives you priority access to all our events, our coworking spaces as well as distinguishes you as a pioneer member within the community.</p>
                        </div>
                    </div>

                    <div className="benefit-col">
                        <CheckBadgeIcon className="size-8 lg:size-14 text-secondary -mb-6 lg:-mb-14" />
                        <div className="benefit-box bg-light-orange-9 ml-2 lg:ml-4 px-6 lg:px-12 py-6 rounded-lg">
                            <h2 className="text-lg lg:text-2xl font-medium text-black-1">One free business event</h2>
                            <p className="text-sm lg:text-base lg:max-w-lg text-dark-grey-1 pt-3">Host one business event or partner meeting for free within your membership year.</p>
                        </div>
                    </div>
                </div>

                <div className="benefit flex flex-col gap-y-4 lg:gap-y-6 ">
                <div className="benefit-col mt-4">
                        <CheckBadgeIcon className="size-8 lg:size-14  text-secondary -mb-6 lg:-mb-14" />
                        <div className="benefit-box bg-light-orange-9 ml-4 px-6 lg:px-12 py-6 rounded-lg">
                            <h2 className="text-lg lg:text-2xl font-medium text-black-1">Professional business address</h2>
                            <p className="text-sm lg:text-base lg:max-w-lg text-dark-grey-1 pt-3">Boost credibility with a verified Lagos business address even if you’re based outside Lagos, you can now have a presence in our business hub.</p>
                        </div>
                    </div>
                    <div className="benefit-col">
                        <CheckBadgeIcon className="size-8 lg:size-14 text-secondary -mb-6 lg:-mb-14" />
                        <div className="benefit-box bg-light-orange-9 ml-4 px-6 lg:px-12 py-6 rounded-lg">
                            <h2 className="text-lg lg:text-2xl font-medium text-black-1">Mail & package handling</h2>
                            <p className="text-sm lg:text-base lg:max-w-lg text-dark-grey-1 pt-3 lg:pb-6">Receive and store your business mail and packages securely at our location.</p>
                        </div>
                    </div>

                    <div className="benefit-col lg:mt-4">
                        <CheckBadgeIcon className="size-8 lg:size-14 text-secondary -mb-6 lg:-mb-14" />
                        <div className="benefit-box bg-light-orange-9 ml-4 px-6 lg:px-12 py-6 rounded-lg">
                            <h2 className="text-lg lg:text-2xl font-medium text-black-1">Free coworking access</h2>
                            <p className="text-sm lg:text-base lg:max-w-lg text-dark-grey-1 pt-3">Work from our space once a week anytime, network with other women entrepreneurs, and conduct your business in a professional space.</p>
                        </div>
                    </div>
                </div>

            </div>



        </section>
    )

}

