import { ArrowRightIcon } from "@heroicons/react/20/solid";


export default function Offer() {

    return (
        <section className="bg-background-grey pt-10 pb-10 lg:pt-20 lg:pb-28 px-6 lg:px-24 lg:mt-16">
            <div className="content h-auto flex items-center justify-center lg:items-start lg:justify-center  bg-light-orange-3 rounded-lg lg:rounded-3xl lg:rounded-large-x">
            <div className="w-full  -mb-4 -mr-3 mt-4 lg:-mb-14 lg:mt-14 lg:ml-14 lg:-mr-8 content-box flex flex-col items-center justify-center  border border-black rounded-xl lg:rounded-large-x py-10 lg:py-20 px-6 lg:px-20 lg:py-20 lg:px-48 text-center">

            <h1 className="text-3xl lg:text-5xl lg:max-w-2xl font-semibold text-black-4 text-center">Exclusive Limited-Time Offers 🔥🔥</h1>
              
              <div className="pill bg-secondary py-1 px-3 rounded-xl mt-8"></div>
              <p className="text-dark-grey text-base lg:text-xl mt-4 lg:max-w-sm">First 50 members get a 30-minute business strategy session!!</p>
          
              <a href="/gold/register" className="hidden lg:flex w-full text-center inline-flex items-center justify-center rounded-xl lg:rounded-full bg-primary py-3 lg:py-5 px-4 lg:px-16 text-sm lg:text-base font-medium text-white mt-8 mb-4">
              I AM READY FOR THIS, CLICK HERE TO GET STARTED
                <ArrowRightIcon className="ml-1 h-6 w-6 text-gray-500" aria-hidden="true" />
                </a>

                <a href="/gold/register" className="lg:hidden w-full text-center inline-flex items-center justify-center rounded-full bg-primary py-3 lg:py-5 px-4 lg:px-16 text-sm lg:text-base font-medium text-white mt-8 mb-4">
              I AM READY
                <ArrowRightIcon className="ml-1 h-6 w-6 text-gray-500" aria-hidden="true" />
                </a>

              <div className="pill bg-secondary py-1 px-3 rounded-xl mt-2 lg:mt-8"></div>
              <p className="text-dark-grey text-base lg:text-xl mt-4 lg:max-w-sm">Refer 5 friends and get your membership renewed at a 20% discount!</p>
          
            </div>
            </div>

        </section>


    )
}