
import ScrollAnimation from 'react-animate-on-scroll';

export default function About() {
  return (
    <>
      <ScrollAnimation animateIn='fadeIn' 
        >
        <section className="shecluded-gold-about bg-background-grey px-6 lg:px-28 pb-14 mt-10 lg:mt-0 flex items-center justify-center">

          <div className="content bg-primary rounded-3xl lg:rounded-large-x py-8 px-6 lg:py-24 lg:px-48 text-center text-white border border-outline-dark">
            <h1 className="text-2xl lg:text-3xl font-semibold">About Shecluded</h1>
            <p className="text-base lg:text-lg pt-8">Shecluded is a female-focused finance and capacity-building company on a mission to unlock economic prosperity for African female entrepreneurs.</p>
            <p className="text-base lg:text-lg pt-6">Since 2019, we have been at the forefront of funding, educating, and supporting women entrepreneurs across Africa. We’ve disbursed over $4 million in funding, trained 50,000 women across 4 African countries, and provided countless women with the financial tools they need to grow. </p>
            <p className="text-base lg:text-lg pt-6">Now, Shecluded Gold & Elite is here to take that impact further</p>
          </div>
        </section>
      </ScrollAnimation>
    </>
  )
}



