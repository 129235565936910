
import Footer from "components/layout/footer";
import About from "./about";
import Benefit from "./benefit";
import GoldFaq from "./faq";
import Header from "./header";
import Offer from "./offer";
import PurposeSlide from "./purposeSlider";
import Remark from "./remark";
import Review from "./review";
import Support from "./support";



export default function ShecludedGoldPage(){
    return (
        <>
         <Header />
          <About />
          <Support />
          <Benefit />
          <PurposeSlide />
          <Offer />
          <Remark />
          <Review />
          <GoldFaq />
          <Footer />
        </>

    )
}