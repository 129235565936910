'use client'

import { useState } from 'react'
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from '@headlessui/react'

import {
  Bars3Icon,
  BanknotesIcon,
  HomeModernIcon,
  Square3Stack3DIcon,
  XMarkIcon,
  ArrowTrendingUpIcon,
  PresentationChartBarIcon,
  UserGroupIcon,
  BuildingStorefrontIcon
} from '@heroicons/react/24/outline'

import { ChevronDownIcon } from '@heroicons/react/20/solid'
import Actionbutton from '../elements/buttons/button'
import { Link } from 'react-router-dom'

const product = [
  { name: 'Loan', description: 'See application criteria and apply for loans', href: '/loans', icon: BanknotesIcon, bgColor: 'bg-light-pink', iconColor: 'text-primary', },
  { name: 'Shecluded Hub', description: 'Learn more about shecluded hub', href: '/hub', icon: HomeModernIcon, bgColor: 'bg-light-orange', iconColor: 'text-secondary', },
  { name: 'Shecluded Gold', description: 'Elevate your business', href: '/gold', icon: BuildingStorefrontIcon, bgColor: 'bg-gold-light', iconColor: 'text-gold', },
]

const business = [
  { name: 'Support', description: 'Get business solutions and strategic directions', href: '/business-support', icon: ArrowTrendingUpIcon, bgColor: 'bg-light-pink', iconColor: 'text-primary', },
  { name: 'Our Programs', description: 'Learn more about our programs', href: '/business-support#programs', icon: PresentationChartBarIcon, bgColor: 'bg-light-orange', iconColor: 'text-secondary', },
  { name: 'Community', description: 'Join a vibrant network of like-minded women', href: '/community', icon: UserGroupIcon, bgColor: 'bg-light-green', iconColor: 'text-tertiary', },
]

const company = [
  { name: 'About', href: '/about', icon: BanknotesIcon, bgColor: 'bg-light-pink', iconColor: 'text-primary', },
  { name: 'Career', href: '/careers', icon: HomeModernIcon, bgColor: 'bg-light-orange', iconColor: 'text-secondary', },
  { name: 'Press', href: '/press', icon: Square3Stack3DIcon, bgColor: 'bg-light-green', iconColor: 'text-tertiary', },
  { name: 'Stories', href: '/stories', icon: Square3Stack3DIcon, bgColor: 'bg-light-green', iconColor: 'text-tertiary', },
]


export default function NavBar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className='bg-white'>
      <nav aria-label="Global" className="mx-auto flex max-w-7xl items-center justify-between pt-4 lg:pb-3 pb-4 px-4 lg:px-8">
        <div className="flex lg:flex ">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Shecluded</span>
            <img
              alt="shecluded logo pink and black "
              src="/logo.svg"
              className=""
            />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        <PopoverGroup className="hidden lg:flex lg:gap-x-12 mx-12">
          <Popover className="relative">
            <PopoverButton className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-text-color focus:outline-none
">
              Business
              <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
            </PopoverButton>
            <PopoverPanel
              transition
              className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-sm overflow-hidden rounded-3xl bg-white shadow-lg  transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="p-4">
                {business.map((item) => (
                  <div
                    key={item.name}
                    className="group relative flex items-center gap-x-4 rounded-lg p-4 text-sm text-text-color leading-6 hover:bg-light-grey-4"
                  >
                    <div className={`flex h-11 w-11 flex-none items-center justify-center rounded-full ${item.bgColor}`}>
                      <item.icon aria-hidden="true" className={`h-6 w-6 ${item.iconColor} group-hover:text-indigo-6001`} />
                    </div>
                    <div className="flex-auto">
                      <a href={item.href} className="block text-sm font-semibold text-text-color">
                        {item.name}
                        <span className="absolute inset-0" />
                      </a>
                      <p className="mt-1 text-dark-grey-1 font-medium text-sm">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </PopoverPanel>
          </Popover>
          <Link to="https://blog.shecluded.com/" className="text-sm font-semibold leading-6 text-text-color">
            Blog
          </Link>
          
          <Popover className="relative">
            <PopoverButton className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-text-color focus:outline-none
">
              Product
              <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
            </PopoverButton>

            <PopoverPanel
              transition
              className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-sm overflow-hidden rounded-3xl bg-white shadow-lg  transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="p-4">
                {product.map((item) => (
                  <div
                    key={item.name}
                    className="group relative flex items-center gap-x-4 rounded-lg p-4 text-sm leading-6 hover:bg-light-grey-4"
                  >
                    <div className={`flex h-11 w-11 flex-none items-center justify-center rounded-full ${item.bgColor}`}>
                      <item.icon aria-hidden="true" className={`h-6 w-6 ${item.iconColor} group-hover:text-indigo-6001`} />
                    </div>
                    <div className="flex-auto">
                      <a href={item.href} className="block text-sm font-semibold text-text-color">
                        {item.name}
                        <span className="absolute inset-0" />
                      </a>
                      <p className="mt-1 text-dark-grey-1 font-medium text-sm">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </PopoverPanel>
          </Popover>

          <Popover className="relative">
            <PopoverButton className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-text-color focus:outline-none">
              Company
              <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
            </PopoverButton>

            <PopoverPanel
              transition
              className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-xs overflow-hidden rounded-3xl bg-white shadow-lg  transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="p-4">
                {company.map((item) => (
                  <div
                    key={item.name}
                    className="group relative flex items-center gap-x-4 rounded-lg p-4 text-sm leading-6 hover:bg-light-grey-4"
                  >

                    <div className="flex-auto">
                      <a href={item.href} className="block text-sm font-semibold text-text-color">
                        {item.name}
                        <span className="absolute inset-0" />
                      </a>
                      <p className="mt-1 text-dark-grey-1 font-medium text-sm">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </PopoverPanel>
          </Popover>

          <Link to="/learn" className="text-sm font-semibold leading-6 text-text-color">
            Learn
          </Link>
        </PopoverGroup>
       
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <Link to="https://dashboard.shecluded.com/login" className="text-sm font-semibold leading-10 text-color mr-4 ">
            Log in
          </Link>
          <Actionbutton to={'https://dashboard.shecluded.com/register'} text={"Sign up"} />
        </div>
      </nav>

      {/* Mobile Screen NavBar */}

      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full  bg-primary px-4 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Shecluded</span>
              <img
                alt="shecluded logo with white background"
                src="/logo_white.svg"
              />
            </a>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-white"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>

          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-white">
              <div className="space-y-4 py-6">
                <Link to="/about" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white">
                  About
                </Link>
                <Disclosure as="div" className="-mx-3">
                  <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base  font-semibold leading-7 text-white">
                    Business
                    <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex text-gray-400 group-data-[open]:rotate-180" />
                  </DisclosureButton>
                  <DisclosurePanel className="mt-2 space-y-2">
                    {[...business].map((item) => (
                      <DisclosureButton
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-lg py-2 pl-3 pr-3 text-base font-medium leading-10 text-white"
                      >
                        <div className='flex gap-x-2 align-center'>
                          <div className="flex h-10 w-10 flex-none items-center justify-center rounded-full bg-white group-hover:bg-white">
                            <item.icon aria-hidden="true" className={`h-5 w-5  ${item.iconColor} group-hover:text-indigo-600 `} />
                          </div>
                          <div className='flex flex-col -mt-2'>
                            {item.name}
                            <p className="text-light-grey-4 font-medium text-sm -mt-2">{item.description}</p>
                          </div>
                        </div>

                      </DisclosureButton>

                    ))}
                  </DisclosurePanel>
                </Disclosure>
                <Link to="/" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white">
                  Blog
                </Link>
                <Disclosure as="div" className="-mx-3">
                  <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-white">
                    Product
                    <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none group-data-[open]:rotate-180" />
                  </DisclosureButton>
                  <DisclosurePanel className="mt-2 space-y-2">
                    {[...product].map((item) => (
                      <DisclosureButton
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-lg py-2 pl-3 pr-3 text-base font-medium leading-10 text-white"
                      >
                        <div className='flex gap-x-2 align-center'>
                          <div className="flex h-10 w-10 flex-none items-center justify-center rounded-full bg-white group-hover:bg-white">
                            <item.icon aria-hidden="true" className={`h-5 w-5  ${item.iconColor} group-hover:text-indigo-600 `} />
                          </div>
                          <div className='flex flex-col -mt-2'>
                            {item.name}
                            <p className="text-light-grey-4 font-medium text-sm -mt-2">{item.description}</p>
                          </div>
                        </div>

                      </DisclosureButton>

                    ))}
                  </DisclosurePanel>
                </Disclosure>

                <Disclosure as="div" className="-mx-3">
                  <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-white">
                    Company
                    <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none group-data-[open]:rotate-180" />
                  </DisclosureButton>
                  <DisclosurePanel className="mt-2 space-y-2">
                    {[...company].map((item) => (
                      <DisclosureButton
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-lg py-2 pl-3 pr-3 text-base font-medium leading-10 text-white"
                      >
                        <div className='flex flex-col -mt-2'>
                          {item.name}
                          <p className="text-light-grey-4 font-medium text-sm -mt-1">{item.description}</p>
                        </div>

                      </DisclosureButton>

                    ))}
                  </DisclosurePanel>
                </Disclosure>

                <Link to="/learn" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white">
                  Learn
                </Link>


                <div className="action-links flex flex-col pt-6">
                  <Link to="https://dashboard.shecluded.com/login" className="text-base font-semibold leading-10 text-white mr-4 ">
                    Log in
                  </Link>
                  <Link to="https://dashboard.shecluded.com/register" className="mt-2 w-full  items-center rounded-full bg-white py-3 px-6 text-base text-center font-semibold text-primary">
                    Sign Up
                  </Link>
                </div>
              </div>

            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </div>
  )
}
