import { ArrowRightIcon } from "@heroicons/react/20/solid";
import ScrollAnimation from "react-animate-on-scroll";


export default function Support() {
    return (
        <section className="gold-support px-6 lg:px-20 mb-10 lg:mb-16">
            <div className="content flex flex-col lg:flex-row  lg:items-center lg:gap-x-20">
                
                <div className="col">
                    <ScrollAnimation animateIn='fadeIn'>
                        <img src="https://shecluded-medias.s3.us-east-2.amazonaws.com/gold-support.png" className="w-full lg:w-[600px] h-auto" alt="woman in white top and jean holding hands on her head"></img>
                    </ScrollAnimation>
                </div>

                <div className="col">
                    <div className="title pt-4 lg:pt-10">
                        <h1 className=" text-3xl lg:text-5xl lg:max-w-xl text-text-color tracking-normal font-semibold pt-4 lg:leading-[3.5rem]">Struggling to access <span className="font-serif italic text-primary font-semibold ">
                            funding</span>, or find the right <span className="font-serif italic text-primary font-semibold ">support system?</span>
                        </h1>

                        <p className="max-w-sm md:max-w-xl py-4 lg:py-6 text-dark-grey-1 text-base font-normal">Are you feeling burned out from juggling everything alone? The loneliness and self-doubt that come with entrepreneurship can drain your confidence.
                            Shecluded Gold gives you the financial resources, professional workspace, and empowering network you need to grow and thrive as a woman entrepreneur.</p>
                        <a href="/gold/register" className="inline-flex items-center rounded-full bg-black-1 py-4 px-16 text-base font-medium text-light-grey-4 drop-shadow-lg  transition duration-0 ease-in hover:drop-shadow-xl hover:duration-150">
                            Join Now
                            <ArrowRightIcon className="ml-1 h-6 w-6 text-gray-500" aria-hidden="true" />
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}
