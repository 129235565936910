
export default function BusinessBestieCTA() {
    return (
        <section className="community-banner mt-6 lg:mt-14 py-2 px-6 lg:px-14">
            <div className={`flex flex-col lg:flex-row items-center  bg-primary bg-service-pattern rounded-lg pt-10 lg:pt-10 pb-6 px-6 lg:px-16 lg:gap-x-4`}>
                <div className="left">
                    <button class="rounded-full bg-white/20 px-4 text-sm md:text-base font-medium py-2 text-white text-center">Don’t miss out!!!</button>
                    <h1 className={`lg:max-w-xl text-4xl lg:text-6xl font-serif font-bold text-white  mt-4`}>Let’s hook you up with your <span className="italic text-light-pink-7'">business soulmate!</span></h1>
                  <p className="lg:pr-6 text-white mt-4 lg:mt-3 lg:max-w-lg text-sm lg:text-base">Building a business is tough, but you don’t have to do it alone. Find a like-minded woman who gets it—because success is better with a business soulmate. Ready to meet your entrepreneurial other half?</p>
                    <a href="/business-bestie" className={`mt-6 inline-flex items-center rounded-full bg-white text-text-color py-4 px-16 lg:px-24 text-sm lg:text-base font-semibold drop-shadow-lg text-center transition duration-0 ease-in hover:drop-shadow-xl hover:duration-150`}>
                    Start Now
                    </a>
                </div>

                <div className="right mt-6 lg:mt-0">
                   <img src="https://shecluded-medias.s3.us-east-2.amazonaws.com/bestie-cta.png" alt="ladies smiling at each other"></img>
                </div>
            </div>
        </section>
    )
}




